<template>
  <div class="result-wrapper" :class="{'result-wrapper-color':isYunyingVersion}">

    <div class="title-content" v-if="isDesensitizationMode">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>检测中心</el-breadcrumb-item>
          <el-breadcrumb-item>检测记录</el-breadcrumb-item>
          <el-breadcrumb-item>风险报告</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>{{$route.query.name}}</el-breadcrumb-item> -->
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc-title">
        <div class="qymc">风险报告</div>
        <!--        <div @click="dowmpdf" class="btn-download" v-if="tag!=9">-->
        <!--          <img class="qyImg" src="@/assets/result/pdfdown1-1.png" alt/>-->
        <!--          <span class="txt">下载财税PDF版</span>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="result">
      <div v-if="dataLoaded">
        <OpsSection v-if="isYunyingVersion"></OpsSection>
        <div class="box-wrapper"
             v-if="!isYunyingVersion">
          <div class="title">
            <img
              src="@/assets/result/title_l.png"
              class="title-l"
            />综合风险评估<img
            src="@/assets/result/title_r.png"
            class="title-r"
          />
          </div>
          <TaxSummary
            @switchTab="switchTab"
            :bankName="$route.query.name"
          ></TaxSummary>
        </div>
        <!-- <discount-policy>综合风险评估</discount-policy> -->

        <!--为园区时隐藏-->
        <template v-for="(aTreeData, index) in this.treeData">
          <div :key="index" class="box-wrapper">
            <div class="title" :class="{displayNone: aTreeData.titleName==''}">
              <img src="@/assets/result/title_l.png" class="title-l"/>{{
                aTreeData.titleName
              }}<img src="@/assets/result/title_r.png" class="title-r"/>
            </div>
            <TabContent
              :key="`a_${index}_${aTreeData.titleName}`"
              :node="aTreeData"
            ></TabContent>

            <!-- <Invoice

              v-if="resultTabName == '增值税专用发票风险评估'"
            ></Invoice>
            <risk-overall
              key="b"
              type="tax"
              @maskerBtnClick="handleMaskBtnClick"
              :node="aTreeData"
              v-if="resultTabName == '税务风险评估' && dataLoaded"
            ></risk-overall>
            <risk-overall
              key="c"
              type="finance"
              @maskerBtnClick="handleMaskBtnClick"
              :node="aTreeData"
              v-else-if="resultTabName == '财务风险评估' && dataLoaded"
            ></risk-overall> -->
          </div>
        </template>
      </div>
    </div>
    <DataSafeGuaranteeDialog></DataSafeGuaranteeDialog>
<!--    <ShowShareCode :vertical="true" ref="ShowShareCode" :companyName="companyInfo.companyName"-->
<!--                   v-if="!isYunyingVersion&&!$route.name.includes('share')&&!isDesensitizationMode" :uuid="uuid"></ShowShareCode>-->
    <!--选择版本-->
    <SelectVersion
      :isTryVersion="false"
      ref="selectVersion"
      @versionSelect="handleVersionSelect"
      :versions="avaliableVersions"
      v-if="avaliableVersions.length > 0"
      :handleMaskBtnClick="handleMaskBtnClick"
    ></SelectVersion>
    <!--/选择版本-->
    <!--    <img-->
    <!--      src="@/assets/free/floatSlogan.png"-->
    <!--      @click="handleSloganClick"-->
    <!--      alt=""-->
    <!--      class="floatSlogan"-->
    <!--      v-if="tag == 2"-->
    <!--    />-->
    <VersionDescriptionDialog
      ref="versionDescriptionDialog"
      @apply="handleDialogApply"
    ></VersionDescriptionDialog>

    <!--联系我们-->
<!--    <ContactUs ref="ContactUs" v-if="!isDesensitizationMode"></ContactUs>-->
    <!--联系我们-->


    <!--    <img src="@/assets/result/downloadPdf.png" class="floatDownloadPdf" @click="dowmpdf"-->
    <!--         v-if="showFloatPdf&&!isYunyingVersion">-->

  </div>
</template>
<script>
import appendQuery from "append-query";
import {getPurchasedVersions} from "@/api/api";
import {mapState} from "vuex";
import BreadCrumb from "@/components/BreadCrumb";
import {downPDFurl, unlockHistoryTest} from "@/api/api";
import TabContent from "@/pages/Result/components/TabContent";
import DataSafeGuaranteeDialog from "@/pages/Result/components/DataSafeGuaranteeDialog";
import TaxSummary from "@/pages/Result/components/TaxSummary";
import SelectVersion from "@/pages/Result/components/SelectVersion";
import VersionDescriptionDialog from "@/pages/FreeEdition/VersionDescriptionDialog";
import ContactDialog from "@/pages/FreeEdition/ContactDialog";
import OpsSection from "@/pages/ResultEditor/components/OpsSection/OpsSection.vue"
import ContactUs from "@/pages/Result/components/ContactUs";
import specialResultHeadMixin from "@/pages/Result/specialResultHeadMixin";
import ShowShareCode from "@/pages/Result/components/compents/ShowShareCode";

export default {
  name:"OrmResult",
  components: {
    BreadCrumb,
    TabContent,
    DataSafeGuaranteeDialog,
    VersionDescriptionDialog,
    ShowShareCode,
    TaxSummary,
    SelectVersion,
    OpsSection,
    ContactUs
  },
  data() {
    return {
      showFloatPdf: false,
      avaliableVersions: [],
      currentSlideIndex: 0,
      showdelete: true,
      pdfurl: "",
      showclick: true,
      resultTabName: "综合风险评估",
    };
  },
  props: {
    uuid: String,
  },
  beforeCreate() {

  },
  async mounted() {

    await this.loadData();

    window.addEventListener("scroll", this.scrollEventHandler)


    //未购买或者是试用版启用遮罩
    this.isLocked();

    //弹出解锁对话框
    if (
      this.$route.query.showUnlock && //是否直接弹出
      this.$store.state.Result.showResultMask && //是否被锁了
      (await this.canUnlock()) //购买过了
    ) {
      this.showUnlockTip();
    }
    setTimeout(()=>{
      const anchor = this.$route.query.anchor
      this.$scrollTo(document.querySelector("#node"+anchor), 1000);
    },1000)

  },
  activated() {
    const anchor = this.$route.query.anchor
    this.$scrollTo(document.querySelector("#node"+anchor), 1000);
  },
  mixins:[
    specialResultHeadMixin
  ],
  destroyed() {
    window.removeEventListener("scroll", this.scrollEventHandler)
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("ResultEditor", [
      "tag",
      "overall",
      "maskStatus",
      "treeData",
      "discountPolicyTree",
      "downloadUrl"
    ]),
    //会否是运营版
    isYunyingVersion() {
      return this.tag == 9;
    },
    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0||this.overall;
    },
  },
  methods: {
    scrollEventHandler() {
      if (window.scrollY < 100) {
        this.showFloatPdf = false;

      } else {
        this.showFloatPdf = true;
      }
    },
    handleSloganClick() {
      this.$refs["versionDescriptionDialog"].dialogVisible = true;
    },
    handleDialogApply() {
      this.$refs["contactDialog"].dialogVisible = true;
    },
    switchTab(tabName, index) {
      this.resultTabName = tabName;
    },
    isDisplayTab(contrastKey) {
      if (contrastKey == "CW" || contrastKey == "SW") {
        return this.tag != "5";
      } else if (contrastKey == "FP") {
        return this.tag == "4" || this.tag == "5";
      }
    },
    // handleVersionSelect(version) {
    //   this.unlockHistoryTest(version);
    // },
    //提示解锁
    async showUnlockTip() {
      this.$confirm("是否解锁评测结果?(此操作会消耗规则库可用次数)", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.unlockHistoryTest();
        })
        .catch(() => {
        });
    },
    //遮罩上的解锁(查看更多)按钮按下后
    async handleMaskBtnClick() {
      const canUnlock = await this.canUnlock();

      if (canUnlock) {
        //如果已经买了,有解锁权限,显示解锁提示

        this.showUnlockTip();
      } else {
        //没买 提示购买
        this.showPurchaseTip();
      }
    },
    //去购买提示
    showPurchaseTip() {
      this.$alert("购买报税体检即可查看所有报告内容", "温馨提示", {
        type: "warning",
        confirmButtonText: "立即购买",
      }).then(() => {
        //设置支付后的跳转链接
        const redirectedURL = appendQuery(this.$route.fullPath, "showUnlock=1");
        sessionStorage.setItem("redirectedToAfterPaid", redirectedURL);

        this.$router.push({name: "goods"});
      });
    },
    //判断是否可以直接解锁
    async canUnlock() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let res, purchasedVersions;
      try {
        res = await getPurchasedVersions({token});
        purchasedVersions = res.data.list;
      } catch (error) {
        throw error;
      }
      if (purchasedVersions[this.tag]) {
        return true;
      } else {
        return false;
      }
    },
    //是否被锁了
    isLocked() {
      switch (this.maskStatus) {
        case "1": //正常
          this.$store.commit("Result/setShowResultMask", false);
          break;

        case "2": //没有版本库
        case "3": //没有风险库
        case "4": //都没有
        case "5": //体验版
          this.$store.commit("Result/setShowResultMask", true);
          break;
        default:
          this.$store.commit("Result/setShowResultMask", true);
      }
    },
    //初始化加载数据
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        action: 'orm',
      });
    },
    // pdf下载
    dowmpdf() {
      var a = document.createElement("a");
      a.href = this.downloadUrl;
      a.download = "";
      // a.click()
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    handleNavClick(e) {
      if (e.target.classList.contains("item")) {
        const element = e.target;
        const parent = element.parentNode.children;
        this.currentSlideIndex = [...parent].indexOf(element);
      }
    },
    async unlockHistoryTest() {
      const tag = this.tag;
      let token = JSON.parse(localStorage.getItem("token")).token;
      const {bankId, nsrsbh, zq} = this.$route.query;
      let data = null;
      try {
        data = await unlockHistoryTest(
          this.uuid,
          token,
          bankId,
          nsrsbh,
          zq,
          tag
        );
      } catch (error) {
        throw error;
      }
      if (data.msgCode == 1) {
        //await this.loadData();
        this.$message({
          type: "success",
          message: "解锁成功!",
        });

        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$message({
          type: "error",
          message: data.msgInfo,
        });
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    //删除vuex中的检测发票结果缓存
    const {nsrsbh, uuid, zq, bankId} = this.$route.query;
    const ret = this.$store.cache.delete("Result/loadInvoice", {
      bankId,
      nsrsbh,
      zq,
      uuid,
    });
    next();
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.result-wrapper {
  background #F8FAFB;
  background-size: 100%;
  overflow-y: hidden;
}

.result-wrapper-color {
  background-image: url('~@/assets/result/bg.png');
  background-color: #2F54EB;
}

.box-wrapper {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  border-radius: 16px;
  margin-bottom: 32px;
  overflow: hidden;

  .title {
    font-size: 32px;
    text-align: center;
    color: #030852;
    line-height: 42px;
    margin: 40px 0 32px;

    .title-l {
      width: 144px;
      margin-right: 24px;
    }

    .title-r {
      width: 144px;
      margin-left: 24px;
    }
  }
}

.floatSlogan {
  position: fixed;
  bottom: 70px;
  width: 308px;
  right: 28px;
  cursor: pointer;
}

.result {
  min-width: 500px;
  max-width: 1280px;
  margin: 80px auto 0;

  >>> .bread-crumb-wrapper {
    padding-left: 0;
  }
}

>>> .el-tabs__header {
  border-bottom: none;
  margin: 24px 0 16px;

  .el-tabs__nav-scroll {
  }

  .el-tabs__item {
    border: 0 none;
    line-height: 36px;
    height: 36px;
    margin-right: 16px;
  }

  .el-tabs__item.is-active {
    background: rgba(47, 84, 235, 1);
    border-radius: 18px;
    color: #fff;
  }

  .el-tabs__nav {
    border: 0 none;
  }
}

.title-content {
  // width: 1260px;
  padding: 10px 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.qymc-title {
  display: flex;
}

.qyImg {
  width: 40px;
  height: 40px;
  margin-left: 24px;
}

.btn-download {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #2F54EB;
  cursor: pointer;
}

.floatDownloadPdf {
  width 84px;
  position fixed;
  right 57px;
  top 100px
  cursor pointer
}
</style>
